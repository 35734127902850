import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 500 500" {...props}>
      <path fill="#00000000" stroke="none" d="M0 0L0 500L500 500L500 0L0 0z" />
      <path fill="#49ba82" stroke="none" d="M308 44L308 45L312 45L308 44z" />
      <path fill="#507641" stroke="none" d="M312 44L312 45L315 45L312 44z" />
      <path fill="#434a2c" stroke="none" d="M315 44L315 45L323 45L315 44z" />
      <path fill="#507641" stroke="none" d="M298 45L299 46L298 45z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M299 45L299 46L306 46L299 45M331 45L331 46L338 46L331 45M289 46L289 47L294 47L289 46M340 46L340 47L344 47L340 46z"
      />
      <path fill="#507641" stroke="none" d="M344 46L345 47L344 46z" />
      <path fill="#49ba82" stroke="none" d="M282.667 47.3333L283.333 47.6667L282.667 47.3333z" />
      <path fill="#507641" stroke="none" d="M284 47L285 48L284 47z" />
      <path fill="#434a2c" stroke="none" d="M285 47L285 48L288 48L285 47M346 47L346 48L351 48L346 47z" />
      <path fill="#49ba82" stroke="none" d="M277 48L278 49L277 48z" />
      <path fill="#507641" stroke="none" d="M278 48L279 49L278 48z" />
      <path fill="#434a2c" stroke="none" d="M279 48L279 49L282 49L279 48M352 48L352 49L355 49L352 48z" />
      <path fill="#507641" stroke="none" d="M273.667 49.3333L274.333 49.6667L273.667 49.3333z" />
      <path fill="#434a2c" stroke="none" d="M275.667 49.3333L276.333 49.6667L275.667 49.3333M357 49L358 50L357 49z" />
      <path fill="#507641" stroke="none" d="M358 49L359 50L358 49z" />
      <path fill="#49ba82" stroke="none" d="M359 49L360 50L359 49M268 50L269 51L268 50z" />
      <path fill="#507641" stroke="none" d="M269 50L270 51L269 50z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M270.667 50.3333L271.333 50.6667L270.667 50.3333M360.667 50.3333L361.333 50.6667L360.667 50.3333z"
      />
      <path fill="#507641" stroke="none" d="M362 50L363 51L362 50M264 51L265 52L264 51z" />
      <path fill="#434a2c" stroke="none" d="M265 51L265 52L268 52L265 51M363 51L363 52L366 52L363 51z" />
      <path fill="#49ba82" stroke="none" d="M260 52L261 53L260 52z" />
      <path fill="#507641" stroke="none" d="M261 52L262 53L261 52z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M262.667 52.3333L263.333 52.6667L262.667 52.3333M367.667 52.3333L368.333 52.6667L367.667 52.3333z"
      />
      <path fill="#507641" stroke="none" d="M257 53L258 54L257 53z" />
      <path fill="#434a2c" stroke="none" d="M258.667 53.3333L259.333 53.6667L258.667 53.3333M370 53L371 54L370 53z" />
      <path fill="#507641" stroke="none" d="M371 53L372 54L371 53z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M254.667 54.3333L255.333 54.6667L254.667 54.3333M372.667 54.3333L373.333 54.6667L372.667 54.3333M375.667 55.3333L376.333 55.6667L375.667 55.3333M248 56L249 57L248 56M378 56L379 57L378 56M245.667 57.3333L246.333 57.6667L245.667 57.3333M380 57L381 58L380 57M242.667 58.3333L243.333 58.6667L242.667 58.3333M382 58L383 59L382 58M240 59L241 60L240 59M384 59L385 60L384 59M237 60L238 61L237 60M386 60L387 61L386 60z"
      />
      <path fill="#507641" stroke="none" d="M234 61L235 62L234 61z" />
      <path fill="#434a2c" stroke="none" d="M235 61L236 62L235 61M388 61L388 62L394 65L394 64L388 61z" />
      <path fill="#507641" stroke="none" d="M232 62L233 63L232 62z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M233 62L234 63L233 62M230 63L231 64L230 63M225 65L225 66L229 65L225 65M395 65L397 67L395 65M223 66L224 67L223 66M221 67L222 68L221 67M398 67L400 69L398 67M219 68L220 69L219 68z"
      />
      <path fill="#49ba82" stroke="none" d="M216 69L217 70L216 69z" />
      <path fill="#434a2c" stroke="none" d="M217 69L218 70L217 69M401 69L404 72L401 69z" />
      <path fill="#49ba82" stroke="none" d="M214 70L215 71L214 70z" />
      <path fill="#434a2c" stroke="none" d="M215 70L216 71L215 70z" />
      <path fill="#49ba82" stroke="none" d="M212 71L213 72L212 71z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M213 71L214 72L213 71M211 72L212 73L211 72M405 72L409 76L405 72M303 81L303 82L310 82L303 81z"
      />
      <path fill="#507641" stroke="none" d="M310 81L310 82L325 82L310 81z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M325 81L325 82L328 82L325 81M295.667 82.3333L296.333 82.6667L295.667 82.3333z"
      />
      <path fill="#507641" stroke="none" d="M297 82L297 83L300 83L297 82z" />
      <path
        fill="#49ba82"
        stroke="none"
        d="M300 82.4252C210.185 94.6508 127.022 164.858 96.6921 250C79.4112 298.511 72.4892 368.768 124 399.396C131 403.558 139.053 406.732 147 408.572C164.776 412.688 182.942 412.796 201 410.834C293.635 400.768 378.912 319.994 405.975 233C421.096 184.391 419.51 119.895 368 93.2585C358.561 88.3776 348.467 85.0686 338 83.25C326.074 81.178 312.025 80.7884 300 82.4252z"
      />
      <path fill="#507641" stroke="none" d="M333.667 82.3333L334.333 82.6667L333.667 82.3333z" />
      <path fill="#434a2c" stroke="none" d="M335.667 82.3333L336.333 82.6667L335.667 82.3333M290 83L291 84L290 83z" />
      <path
        fill="#507641"
        stroke="none"
        d="M291.667 83.3333L292.333 83.6667L291.667 83.3333M339.667 83.3333L340.333 83.6667L339.667 83.3333z"
      />
      <path fill="#434a2c" stroke="none" d="M341 83L342 84L341 83M285 84L286 85L285 84z" />
      <path fill="#507641" stroke="none" d="M286.667 84.3333L287.333 84.6667L286.667 84.3333M344 84L345 85L344 84z" />
      <path fill="#434a2c" stroke="none" d="M345 84L346 85L345 84M280 85L281 86L280 85z" />
      <path fill="#507641" stroke="none" d="M281 85L282 86L281 85M348 85L349 86L348 85z" />
      <path fill="#434a2c" stroke="none" d="M349 85L350 86L349 85M276 86L277 87L276 86z" />
      <path fill="#507641" stroke="none" d="M277 86L278 87L277 86M351 86L352 87L351 86z" />
      <path fill="#434a2c" stroke="none" d="M352 86L353 87L352 86M272 87L273 88L272 87z" />
      <path fill="#507641" stroke="none" d="M273 87L274 88L273 87M354 87L355 88L354 87z" />
      <path fill="#434a2c" stroke="none" d="M355 87L356 88L355 87M269 88L270 89L269 88z" />
      <path fill="#507641" stroke="none" d="M270 88L271 89L270 88M357 88L358 89L357 88z" />
      <path fill="#434a2c" stroke="none" d="M358 88L359 89L358 88M266 89L267 90L266 89z" />
      <path fill="#507641" stroke="none" d="M267 89L268 90L267 89M360 89L361 90L360 89z" />
      <path fill="#434a2c" stroke="none" d="M262 90L263 91L262 90z" />
      <path fill="#507641" stroke="none" d="M263 90L264 91L263 90M362 90L363 91L362 90z" />
      <path fill="#434a2c" stroke="none" d="M363 90L364 91L363 90M259 91L260 92L259 91z" />
      <path fill="#507641" stroke="none" d="M260 91L261 92L260 91M364 91L365 92L364 91z" />
      <path fill="#434a2c" stroke="none" d="M365 91L366 92L365 91M256 92L257 93L256 92z" />
      <path fill="#507641" stroke="none" d="M257 92L258 93L257 92M366 92L367 93L366 92z" />
      <path fill="#434a2c" stroke="none" d="M367 92L368 93L367 92M253 93L254 94L253 93z" />
      <path
        fill="#507641"
        stroke="none"
        d="M254 93L255 94L254 93M368 93L369 94L368 93M251 94L252 95L251 94M370 94L371 95L370 94z"
      />
      <path fill="#434a2c" stroke="none" d="M248 95L249 96L248 95z" />
      <path fill="#507641" stroke="none" d="M249 95L250 96L249 95z" />
      <path fill="#434a2c" stroke="none" d="M372 95L373 96L372 95z" />
      <path fill="#507641" stroke="none" d="M246 96L247 97L246 96M373 96L374 97L373 96z" />
      <path fill="#434a2c" stroke="none" d="M243 97L244 98L243 97M375 97L376 98L375 97M241 98L242 99L241 98z" />
      <path fill="#507641" stroke="none" d="M376 98L377 99L376 98M239 99L240 100L239 99z" />
      <path fill="#434a2c" stroke="none" d="M378 99L379 100L378 99z" />
      <path
        fill="#507641"
        stroke="none"
        d="M237 100L238 101L237 100M379 100L380 101L379 100M235 101L236 102L235 101z"
      />
      <path fill="#434a2c" stroke="none" d="M232 102L233 103L232 102z" />
      <path fill="#507641" stroke="none" d="M233 102L234 103L233 102z" />
      <path fill="#434a2c" stroke="none" d="M382 102L383 103L382 102M230 103L231 104L230 103z" />
      <path fill="#507641" stroke="none" d="M231 103L232 104L231 103z" />
      <path fill="#434a2c" stroke="none" d="M383 103L384 104L383 103M228 104L229 105L228 104z" />
      <path fill="#507641" stroke="none" d="M229 104L230 105L229 104z" />
      <path fill="#434a2c" stroke="none" d="M384 104L385 105L384 104M226 105L227 106L226 105z" />
      <path fill="#507641" stroke="none" d="M385 105L386 106L385 105z" />
      <path fill="#434a2c" stroke="none" d="M433 105L434 106L433 105M224 106L225 107L224 106z" />
      <path
        fill="#507641"
        stroke="none"
        d="M225 106L226 107L225 106M386 106L387 107L386 106M223 107L224 108L223 107M387 107L388 108L387 107z"
      />
      <path fill="#434a2c" stroke="none" d="M434 107L435 108L434 107z" />
      <path
        fill="#507641"
        stroke="none"
        d="M221 108L222 109L221 108M388 108L389 109L388 108M435 108L436 109L435 108M219 109L220 110L219 109M307 109L307 110L324 110L307 109M389 109L390 110L389 109z"
      />
      <path fill="#434a2c" stroke="none" d="M435 109L436 110L435 109M217 110L218 111L217 110z" />
      <path fill="#507641" stroke="none" d="M298.667 110.333L299.333 110.667L298.667 110.333z" />
      <path fill="#434a2c" stroke="none" d="M300 110L300 111L303 111L300 110z" />
      <path
        fill="#050519"
        stroke="none"
        d="M303 110.425C223.303 121.274 151.49 187.803 122.45 261C104.449 306.373 106.137 370.818 164 383.576C175.008 386.003 185.853 385.91 197 384.83C279.443 376.847 352.035 300.898 379.576 227C396.925 180.448 387.819 120.653 331 111.081C322.029 109.57 312.041 109.195 303 110.425z"
      />
      <path fill="#434a2c" stroke="none" d="M327.667 110.333L328.333 110.667L327.667 110.333z" />
      <path fill="#507641" stroke="none" d="M329 110L329 111L332 111L329 110z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M390 110L391 111L390 110M436.333 110.667L436.667 111.333L436.333 110.667M215 111L216 112L215 111z"
      />
      <path fill="#507641" stroke="none" d="M293 111L294 112L293 111z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M294.667 111.333L295.333 111.667L294.667 111.333M333.667 111.333L334.333 111.667L333.667 111.333z"
      />
      <path fill="#507641" stroke="none" d="M335 111L336 112L335 111z" />
      <path fill="#434a2c" stroke="none" d="M391 111L392 112L391 111z" />
      <path
        fill="#507641"
        stroke="none"
        d="M214 112L215 113L214 112M288.667 112.333L289.333 112.667L288.667 112.333z"
      />
      <path fill="#434a2c" stroke="none" d="M290 112L291 113L290 112M338 112L339 113L338 112z" />
      <path fill="#507641" stroke="none" d="M339 112L340 113L339 112z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M392 112L393 113L392 112M437.333 112.667L437.667 113.333L437.333 112.667M212 113L213 114L212 113z"
      />
      <path fill="#507641" stroke="none" d="M284 113L285 114L284 113z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M285.667 113.333L286.333 113.667L285.667 113.333M342 113L343 114L342 113z"
      />
      <path fill="#507641" stroke="none" d="M343 113L344 114L343 113z" />
      <path fill="#434a2c" stroke="none" d="M149 114L146 118L149 114M210 114L211 115L210 114z" />
      <path fill="#507641" stroke="none" d="M280.667 114.333L281.333 114.667L280.667 114.333z" />
      <path fill="#434a2c" stroke="none" d="M282 114L283 115L282 114M345 114L346 115L345 114z" />
      <path fill="#507641" stroke="none" d="M346 114L347 115L346 114z" />
      <path fill="#434a2c" stroke="none" d="M438.333 114.667L438.667 115.333L438.333 114.667z" />
      <path fill="#49ba82" stroke="none" d="M147 115L148 116L147 115z" />
      <path fill="#507641" stroke="none" d="M209 115L210 116L209 115M277 115L278 116L277 115z" />
      <path fill="#434a2c" stroke="none" d="M278 115L279 116L278 115M347 115L348 116L347 115z" />
      <path fill="#507641" stroke="none" d="M348 115L349 116L348 115M394 115L395 116L394 115z" />
      <path fill="#49ba82" stroke="none" d="M146 116L147 117L146 116z" />
      <path fill="#434a2c" stroke="none" d="M207 116L208 117L207 116z" />
      <path fill="#507641" stroke="none" d="M274 116L275 117L274 116z" />
      <path fill="#434a2c" stroke="none" d="M275 116L276 117L275 116M350 116L351 117L350 116z" />
      <path fill="#507641" stroke="none" d="M395 116L396 117L395 116z" />
      <path fill="#434a2c" stroke="none" d="M439.333 116.667L439.667 117.333L439.333 116.667z" />
      <path
        fill="#507641"
        stroke="none"
        d="M145 117L146 118L145 117M206 117L207 118L206 117M271 117L272 118L271 117z"
      />
      <path
        fill="#434a2c"
        stroke="none"
        d="M272 117L273 118L272 117M352 117L353 118L352 117M396 117L397 118L396 117z"
      />
      <path fill="#507641" stroke="none" d="M144 118L145 119L144 118z" />
      <path fill="#434a2c" stroke="none" d="M204 118L205 119L204 118z" />
      <path fill="#507641" stroke="none" d="M268 118L269 119L268 118z" />
      <path fill="#434a2c" stroke="none" d="M269 118L270 119L269 118M354 118L355 119L354 118z" />
      <path
        fill="#507641"
        stroke="none"
        d="M143 119L144 120L143 119M203 119L204 120L203 119M265 119L266 120L265 119z"
      />
      <path fill="#434a2c" stroke="none" d="M266 119L267 120L266 119z" />
      <path fill="#507641" stroke="none" d="M356 119L357 120L356 119M397 119L398 120L397 119z" />
      <path fill="#434a2c" stroke="none" d="M440 119L441 120L440 119z" />
      <path fill="#507641" stroke="none" d="M142 120L143 121L142 120z" />
      <path fill="#434a2c" stroke="none" d="M201 120L202 121L201 120M263 120L264 121L263 120z" />
      <path fill="#507641" stroke="none" d="M358 120L359 121L358 120z" />
      <path fill="#434a2c" stroke="none" d="M398 120L399 121L398 120z" />
      <path
        fill="#507641"
        stroke="none"
        d="M141 121L142 122L141 121M200 121L201 122L200 121M260 121L261 122L260 121z"
      />
      <path
        fill="#434a2c"
        stroke="none"
        d="M261 121L262 122L261 121M359 121L360 122L359 121M441.333 121.667L441.667 122.333L441.333 121.667M140 122L141 123L140 122M198 122L199 123L198 122M258 122L259 123L258 122M139 123L140 124L139 123z"
      />
      <path fill="#507641" stroke="none" d="M197 123L198 124L197 123M255 123L256 124L255 123z" />
      <path fill="#434a2c" stroke="none" d="M256 123L257 124L256 123z" />
      <path fill="#507641" stroke="none" d="M362 123L363 124L362 123z" />
      <path fill="#434a2c" stroke="none" d="M400 123L401 124L400 123z" />
      <path fill="#49ba82" stroke="none" d="M442 123L443 124L442 123z" />
      <path fill="#434a2c" stroke="none" d="M138 124L139 125L138 124M195 124L196 125L195 124z" />
      <path fill="#507641" stroke="none" d="M253 124L254 125L253 124z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M254 124L255 125L254 124M363 124L364 125L363 124M442.333 124.667L442.667 125.333L442.333 124.667M137 125L138 126L137 125z"
      />
      <path fill="#507641" stroke="none" d="M194 125L195 126L194 125M251 125L252 126L251 125z" />
      <path fill="#434a2c" stroke="none" d="M364 125L365 126L364 125z" />
      <path fill="#507641" stroke="none" d="M401 125L402 126L401 125z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M136 126L137 127L136 126M249 126L250 127L249 126M443.333 126.667L443.667 127.333L443.333 126.667M135 127L136 128L135 127M191 127L192 128L191 127M247 127L248 128L247 127z"
      />
      <path fill="#507641" stroke="none" d="M367 127L368 128L367 127M402 127L403 128L402 127z" />
      <path fill="#434a2c" stroke="none" d="M134 128L135 129L134 128z" />
      <path fill="#507641" stroke="none" d="M190 128L191 129L190 128z" />
      <path fill="#434a2c" stroke="none" d="M245 128L246 129L245 128z" />
      <path fill="#507641" stroke="none" d="M368 128L369 129L368 128z" />
      <path fill="#434a2c" stroke="none" d="M133 129L134 130L133 129M243 129L244 130L243 129z" />
      <path fill="#507641" stroke="none" d="M369 129L370 130L369 129M403 129L404 130L403 129z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M444 129L445 135L446 135L444 129M131 130L131 132L133 130L131 130M187 130L188 131L187 130M241 130L242 131L241 130z"
      />
      <path fill="#507641" stroke="none" d="M370 130L371 131L370 130z" />
      <path fill="#434a2c" stroke="none" d="M404 130L405 131L404 130z" />
      <path fill="#507641" stroke="none" d="M186 131L187 132L186 131z" />
      <path fill="#434a2c" stroke="none" d="M239 131L240 132L239 131z" />
      <path fill="#507641" stroke="none" d="M371 131L372 132L371 131M404 131L405 132L404 131z" />
      <path fill="#434a2c" stroke="none" d="M130 132L131 133L130 132z" />
      <path fill="#507641" stroke="none" d="M237 132L238 133L237 132M372 132L373 133L372 132z" />
      <path fill="#434a2c" stroke="none" d="M129 133L130 134L129 133M183 133L184 134L183 133z" />
      <path fill="#507641" stroke="none" d="M235 133L236 134L235 133M405 133L406 134L405 133z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M128 134L129 135L128 134M182 134L183 135L182 134M234 134L235 135L234 134M373 134L374 135L373 134M127 135L128 136L127 135z"
      />
      <path fill="#507641" stroke="none" d="M181 135L182 136L181 135z" />
      <path fill="#434a2c" stroke="none" d="M232 135L233 136L232 135M374 135L375 136L374 135z" />
      <path fill="#507641" stroke="none" d="M406 135L407 136L406 135z" />
      <path fill="#434a2c" stroke="none" d="M126 136L127 137L126 136z" />
      <path fill="#507641" stroke="none" d="M230 136L231 137L230 136M375 136L376 137L375 136z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M446.333 136.667L446.667 137.333L446.333 136.667M125 137L126 138L125 137M178 137L179 138L178 137M229 137L230 138L229 137M407 137L408 138L407 137M124 138L125 139L124 138M177 138L178 139L177 138M227 138L228 139L227 138M376 138L377 139L376 138z"
      />
      <path fill="#507641" stroke="none" d="M407 138L408 139L407 138z" />
      <path fill="#434a2c" stroke="none" d="M123 139L124 140L123 139z" />
      <path
        fill="#507641"
        stroke="none"
        d="M176 139L177 140L176 139M225 139L226 140L225 139M447 139L448 140L447 139z"
      />
      <path
        fill="#434a2c"
        stroke="none"
        d="M122 140L123 141L122 140M224 140L225 141L224 140M377 140L378 141L377 140M408 140L409 141L408 140M447 140L447 143L448 143L447 140M121 141L122 142L121 141z"
      />
      <path fill="#507641" stroke="none" d="M222 141L223 142L222 141M378 141L379 142L378 141z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M120 142L121 143L120 142M172 142L173 143L172 142M221 142L222 143L221 142M119 143L120 144L119 143z"
      />
      <path
        fill="#507641"
        stroke="none"
        d="M171 143L172 144L171 143M219 143L220 144L219 143M379 143L380 144L379 143M409 143L410 144L409 143z"
      />
      <path fill="#434a2c" stroke="none" d="M118 144L119 145L118 144z" />
      <path fill="#507641" stroke="none" d="M170 144L171 145L170 144z" />
      <path fill="#434a2c" stroke="none" d="M218 144L219 145L218 144M448 144L448 147L449 147L448 144z" />
      <path fill="#507641" stroke="none" d="M117 145L118 146L117 145M169 145L170 146L169 145z" />
      <path fill="#434a2c" stroke="none" d="M314 145L314 146L320 146L314 145z" />
      <path fill="#507641" stroke="none" d="M380 145L381 146L380 145z" />
      <path fill="#49ba82" stroke="none" d="M116 146L117 147L116 146z" />
      <path fill="#507641" stroke="none" d="M168 146L169 147L168 146M215 146L216 147L215 146z" />
      <path fill="#434a2c" stroke="none" d="M303.667 146.333L304.333 146.667L303.667 146.333z" />
      <path fill="#507641" stroke="none" d="M305 146L305 147L308 147L305 146z" />
      <path
        fill="#baba01"
        stroke="none"
        d="M149 325L180 295L238 237L297 178L327 147C301.483 142.263 273.739 153.721 252 166.15C198.284 196.863 143.832 259.512 149 325z"
      />
      <path fill="#507641" stroke="none" d="M324 146L325 147L324 146z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M325.667 146.333L326.333 146.667L325.667 146.333M410 146L411 147L410 146M116 147L117 148L116 147M214 147L215 148L214 147M298 147L299 148L298 147z"
      />
      <path fill="#507641" stroke="none" d="M299.667 147.333L300.333 147.667L299.667 147.333z" />
      <path fill="#434a2c" stroke="none" d="M327 147L328 148L327 147z" />
      <path fill="#507641" stroke="none" d="M381 147L382 148L381 147M410 147L411 148L410 147z" />
      <path fill="#434a2c" stroke="none" d="M115 148L116 149L115 148z" />
      <path fill="#507641" stroke="none" d="M212 148L213 149L212 148z" />
      <path fill="#434a2c" stroke="none" d="M294 148L295 149L294 148z" />
      <path fill="#507641" stroke="none" d="M295 148L296 149L295 148z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M326 148L327 149L326 148M114 149L115 150L114 149M211 149L212 150L211 149M290 149L291 150L290 149z"
      />
      <path fill="#507641" stroke="none" d="M291 149L292 150L291 149z" />
      <path fill="#434a2c" stroke="none" d="M325 149L326 150L325 149z" />
      <path fill="#507641" stroke="none" d="M382 149L383 150L382 149z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M411 149L412 150L411 149M449 149L449 153L450 153L449 149M113 150L114 151L113 150M287 150L288 151L287 150z"
      />
      <path fill="#507641" stroke="none" d="M288 150L289 151L288 150z" />
      <path fill="#434a2c" stroke="none" d="M324 150L325 151L324 150M382 150L383 151L382 150z" />
      <path fill="#507641" stroke="none" d="M411.333 150.667L411.667 151.333L411.333 150.667z" />
      <path fill="#434a2c" stroke="none" d="M112 151L113 152L112 151z" />
      <path fill="#507641" stroke="none" d="M208 151L209 152L208 151z" />
      <path fill="#434a2c" stroke="none" d="M283 151L284 152L283 151z" />
      <path fill="#507641" stroke="none" d="M284 151L285 152L284 151z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M323 151L324 152L323 151M111.333 152.667L111.667 153.333L111.333 152.667M207 152L208 153L207 152M280 152L281 153L280 152z"
      />
      <path fill="#507641" stroke="none" d="M281 152L282 153L281 152z" />
      <path fill="#434a2c" stroke="none" d="M322 152L323 153L322 152z" />
      <path fill="#507641" stroke="none" d="M383 152L384 153L383 152z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M160 153L161 154L160 153M278 153L279 154L278 153M321 153L322 154L321 153M383 153L384 154L383 153M412.333 153.667L412.667 154.333L412.333 153.667M110 154L111 155L110 154M159 154L160 155L159 154z"
      />
      <path fill="#507641" stroke="none" d="M204 154L205 155L204 154z" />
      <path fill="#434a2c" stroke="none" d="M275 154L276 155L275 154z" />
      <path fill="#507641" stroke="none" d="M276 154L277 155L276 154z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M320 154L321 155L320 154M109 155L110 156L109 155M158 155L159 156L158 155M203 155L204 156L203 155M273 155L274 156L273 155M319 155L320 156L319 155z"
      />
      <path fill="#507641" stroke="none" d="M384 155L385 156L384 155M412 155L413 156L412 155z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M450 155L450 161L451 161L450 155M108 156L109 157L108 156M157 156L158 157L157 156M202 156L203 157L202 156z"
      />
      <path fill="#507641" stroke="none" d="M271 156L272 157L271 156z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M318 156L319 157L318 156M384 156L385 157L384 156M107 157L108 158L107 157M156 157L157 158L156 157M268 157L269 158L268 157z"
      />
      <path fill="#507641" stroke="none" d="M269 157L270 158L269 157z" />
      <path fill="#434a2c" stroke="none" d="M317 157L318 158L317 157M155 158L156 159L155 158z" />
      <path fill="#507641" stroke="none" d="M199 158L200 159L199 158z" />
      <path fill="#434a2c" stroke="none" d="M266 158L267 159L266 158M316 158L317 159L316 158z" />
      <path fill="#507641" stroke="none" d="M385 158L386 159L385 158z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M106 159L104 162L106 159M198 159L199 160L198 159M264 159L265 160L264 159M315 159L316 160L315 159M385.333 159.667L385.667 160.333L385.333 159.667M413.333 159.667L413.667 160.333L413.333 159.667M197 160L198 161L197 160z"
      />
      <path fill="#507641" stroke="none" d="M262 160L263 161L262 160z" />
      <path fill="#434a2c" stroke="none" d="M314 160L315 161L314 160z" />
      <path fill="#507641" stroke="none" d="M260 161L261 162L260 161z" />
      <path fill="#434a2c" stroke="none" d="M313 161L314 162L313 161z" />
      <path
        fill="#507641"
        stroke="none"
        d="M413.333 161.667L413.667 162.333L413.333 161.667M194 162L195 163L194 162M258 162L259 163L258 162z"
      />
      <path fill="#434a2c" stroke="none" d="M312 162L313 163L312 162z" />
      <path fill="#507641" stroke="none" d="M386.333 162.667L386.667 163.333L386.333 162.667z" />
      <path fill="#434a2c" stroke="none" d="M103 163L101 166L103 163z" />
      <path fill="#507641" stroke="none" d="M193 163L194 164L193 163z" />
      <path fill="#434a2c" stroke="none" d="M256 163L257 164L256 163M311 163L312 164L311 163z" />
      <path fill="#507641" stroke="none" d="M192 164L193 165L192 164z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M254 164L255 165L254 164M310 164L311 165L310 164M386 164L387 165L386 164z"
      />
      <path fill="#49ba82" stroke="none" d="M451 164L452 165L451 164z" />
      <path fill="#507641" stroke="none" d="M149 165L150 166L149 165z" />
      <path fill="#434a2c" stroke="none" d="M191 165L192 166L191 165z" />
      <path fill="#507641" stroke="none" d="M253 165L254 166L253 165z" />
      <path fill="#434a2c" stroke="none" d="M309 165L310 166L309 165z" />
      <path fill="#507641" stroke="none" d="M451 165L452 166L451 165M148 166L149 167L148 166z" />
      <path fill="#434a2c" stroke="none" d="M190 166L191 167L190 166z" />
      <path fill="#507641" stroke="none" d="M251 166L252 167L251 166z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M308 166L309 167L308 166M414 166L414 169L415 169L414 166M451 166L451 178L452 178L451 166M100 167L96 173L100 167z"
      />
      <path fill="#507641" stroke="none" d="M147 167L148 168L147 167z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M189 167L190 168L189 167M249 167L250 168L249 167M307 167L308 168L307 167M146 168L147 169L146 168M188 168L189 169L188 168M306 168L307 169L306 168z"
      />
      <path fill="#507641" stroke="none" d="M387.333 168.667L387.667 169.333L387.333 168.667z" />
      <path fill="#434a2c" stroke="none" d="M145 169L146 170L145 169z" />
      <path fill="#507641" stroke="none" d="M246 169L247 170L246 169z" />
      <path fill="#434a2c" stroke="none" d="M305 169L306 170L305 169z" />
      <path fill="#507641" stroke="none" d="M414 169L414 174L415 174L414 169z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M144 170L145 171L144 170M244 170L245 171L244 170M304 170L305 171L304 170M387 170L387 174L388 174L387 170z"
      />
      <path fill="#507641" stroke="none" d="M243 171L244 172L243 171z" />
      <path fill="#434a2c" stroke="none" d="M303 171L304 172L303 171z" />
      <path fill="#507641" stroke="none" d="M183 172L184 173L183 172z" />
      <path fill="#434a2c" stroke="none" d="M241 172L242 173L241 172M302 172L303 173L302 172z" />
      <path fill="#507641" stroke="none" d="M182 173L183 174L182 173M240 173L241 174L240 173z" />
      <path fill="#434a2c" stroke="none" d="M301 173L302 174L301 173M95 174L94 176L95 174z" />
      <path fill="#507641" stroke="none" d="M141 174L142 175L141 174M181 174L182 175L181 174z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M238 174L239 175L238 174M300 174L301 175L300 174M140 175L141 176L140 175z"
      />
      <path fill="#507641" stroke="none" d="M180 175L181 176L180 175M237 175L238 176L237 175z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M299 175L300 176L299 175M351 175L352 176L351 175M139 176L140 177L139 176z"
      />
      <path fill="#507641" stroke="none" d="M179 176L180 177L179 176z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M235 176L236 177L235 176M298 176L299 177L298 176M349 176L350 177L349 176z"
      />
      <path
        fill="#baba01"
        stroke="none"
        d="M350 176L235 291C216.512 309.488 193.046 327.641 178 349C243.021 349 305.846 295.861 334.244 240C342.43 223.896 357.795 194.576 350 176z"
      />
      <path fill="#507641" stroke="none" d="M351 176L351 187L352 187L351 176z" />
      <path fill="#434a2c" stroke="none" d="M93 177L92 179L93 177z" />
      <path fill="#507641" stroke="none" d="M178 177L179 178L178 177M234 177L235 178L234 177z" />
      <path fill="#434a2c" stroke="none" d="M297 177L298 178L297 177z" />
      <path fill="#507641" stroke="none" d="M177 178L178 179L177 178z" />
      <path fill="#434a2c" stroke="none" d="M296 178L297 179L296 178M347 178L348 179L347 178z" />
      <path fill="#507641" stroke="none" d="M176 179L177 180L176 179z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M231 179L232 180L231 179M295 179L296 180L295 179M451 179L451 193L452 193L451 179M91 180L90 182L91 180z"
      />
      <path
        fill="#507641"
        stroke="none"
        d="M136 180L137 181L136 180M175 180L176 181L175 180M230 180L231 181L230 180z"
      />
      <path fill="#434a2c" stroke="none" d="M294 180L295 181L294 180M135 181L136 182L135 181z" />
      <path fill="#507641" stroke="none" d="M174 181L175 182L174 181z" />
      <path fill="#434a2c" stroke="none" d="M293 181L294 182L293 181M344 181L345 182L344 181z" />
      <path fill="#507641" stroke="none" d="M173 182L174 183L173 182z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M227 182L228 183L227 182M292 182L293 183L292 182M343 182L344 183L343 182M89 183L88 185L89 183z"
      />
      <path fill="#507641" stroke="none" d="M226 183L227 184L226 183z" />
      <path fill="#434a2c" stroke="none" d="M291 183L292 184L291 183M342 183L343 184L342 183z" />
      <path fill="#507641" stroke="none" d="M133 184L134 185L133 184M225 184L226 185L225 184z" />
      <path fill="#434a2c" stroke="none" d="M290 184L291 185L290 184M341 184L342 185L341 184z" />
      <path fill="#507641" stroke="none" d="M132 185L133 186L132 185z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M223 185L224 186L223 185M289 185L290 186L289 185M87 186L85 190L86 190L87 186M131 186L132 187L131 186M222 186L223 187L222 186M288 186L289 187L288 186M169 187L170 188L169 187z"
      />
      <path fill="#507641" stroke="none" d="M221 187L222 188L221 187z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M287 187L288 188L287 187M338 187L339 188L338 187M351 187L351 190L352 190L351 187M168 188L169 189L168 188z"
      />
      <path fill="#507641" stroke="none" d="M220 188L221 189L220 188z" />
      <path fill="#434a2c" stroke="none" d="M286 188L287 189L286 188M337 188L338 189L337 188z" />
      <path fill="#507641" stroke="none" d="M129 189L130 190L129 189z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M167 189L168 190L167 189M285 189L286 190L285 189M336 189L337 190L336 189M387 189L387 193L388 193L387 189z"
      />
      <path fill="#507641" stroke="none" d="M414 189L414 193L415 193L414 189z" />
      <path fill="#434a2c" stroke="none" d="M128 190L129 191L128 190z" />
      <path fill="#507641" stroke="none" d="M166 190L167 191L166 190z" />
      <path fill="#434a2c" stroke="none" d="M284 190L285 191L284 190M84 191L83 194L84 194L84 191z" />
      <path fill="#507641" stroke="none" d="M165 191L166 192L165 191z" />
      <path fill="#434a2c" stroke="none" d="M283 191L284 192L283 191z" />
      <path fill="#507641" stroke="none" d="M127 192L128 193L127 192M164 192L165 193L164 192z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M215 192L216 193L215 192M282 192L283 193L282 192M333 192L334 193L333 192M126 193L127 194L126 193M214 193L215 194L214 193M281 193L282 194L281 193z"
      />
      <path
        fill="#507641"
        stroke="none"
        d="M350.333 193.667L350.667 194.333L350.333 193.667M387 193L387 196L388 196L387 193z"
      />
      <path fill="#434a2c" stroke="none" d="M414 193L414 196L415 196L414 193z" />
      <path fill="#507641" stroke="none" d="M82 194L83 195L82 194z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M213 194L214 195L213 194M280 194L281 195L280 194M331 194L332 195L331 194z"
      />
      <path fill="#507641" stroke="none" d="M125 195L126 196L125 195z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M162 195L163 196L162 195M212 195L213 196L212 195M279 195L280 196L279 195M330 195L331 196L330 195M350.333 195.667L350.667 196.333L350.333 195.667M81 196L82 197L81 196M124 196L125 197L124 196M161 196L162 197L161 196z"
      />
      <path fill="#507641" stroke="none" d="M211 196L212 197L211 196z" />
      <path fill="#434a2c" stroke="none" d="M278 196L279 197L278 196M329 196L330 197L329 196z" />
      <path fill="#49ba82" stroke="none" d="M80 197L81 198L80 197z" />
      <path fill="#507641" stroke="none" d="M160 197L161 198L160 197M210 197L211 198L210 197z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M277 197L278 198L277 197M328 197L329 198L328 197M450 197L450 203L451 203L450 197M80 198L81 199L80 198z"
      />
      <path
        fill="#507641"
        stroke="none"
        d="M123 198L124 199L123 198M159 198L160 199L159 198M209 198L210 199L209 198z"
      />
      <path
        fill="#434a2c"
        stroke="none"
        d="M276 198L277 199L276 198M327 198L328 199L327 198M386.333 198.667L386.667 199.333L386.333 198.667M79.3333 199.667L79.6667 200.333L79.3333 199.667M122 199L123 200L122 199z"
      />
      <path fill="#507641" stroke="none" d="M208 199L209 200L208 199z" />
      <path fill="#434a2c" stroke="none" d="M275 199L276 200L275 199M326 199L327 200L326 199z" />
      <path
        fill="#507641"
        stroke="none"
        d="M349 199L350 200L349 199M413.333 199.667L413.667 200.333L413.333 199.667M207 200L208 201L207 200z"
      />
      <path
        fill="#434a2c"
        stroke="none"
        d="M274 200L275 201L274 200M325 200L326 201L325 200M349.333 200.667L349.667 201.333L349.333 200.667z"
      />
      <path fill="#507641" stroke="none" d="M386.333 200.667L386.667 201.333L386.333 200.667z" />
      <path fill="#434a2c" stroke="none" d="M78 201L79 202L78 201z" />
      <path fill="#507641" stroke="none" d="M121 201L122 202L121 201z" />
      <path fill="#434a2c" stroke="none" d="M157 201L158 202L157 201z" />
      <path fill="#507641" stroke="none" d="M206 201L207 202L206 201z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M273 201L274 202L273 201M324 201L325 202L324 201M413.333 201.667L413.667 202.333L413.333 201.667M120 202L121 203L120 202M156 202L157 203L156 202M272 202L273 203L272 202M323 202L324 203L323 202z"
      />
      <path fill="#507641" stroke="none" d="M155 203L156 204L155 203M204 203L205 204L204 203z" />
      <path fill="#434a2c" stroke="none" d="M271 203L272 204L271 203M322 203L323 204L322 203z" />
      <path fill="#507641" stroke="none" d="M119 204L120 205L119 204M203 204L204 205L203 204z" />
      <path fill="#434a2c" stroke="none" d="M270 204L271 205L270 204M321 204L322 205L321 204z" />
      <path fill="#507641" stroke="none" d="M348 204L349 205L348 204z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M385.333 204.667L385.667 205.333L385.333 204.667M118 205L119 206L118 205M154 205L155 206L154 205z"
      />
      <path fill="#507641" stroke="none" d="M202 205L203 206L202 205z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M269 205L270 206L269 205M320 205L321 206L320 205M348 205L349 206L348 205M153 206L154 207L153 206z"
      />
      <path fill="#507641" stroke="none" d="M201 206L202 207L201 206z" />
      <path fill="#434a2c" stroke="none" d="M268 206L269 207L268 206M319 206L320 207L319 206z" />
      <path
        fill="#507641"
        stroke="none"
        d="M385 206L386 207L385 206M412.333 206.667L412.667 207.333L412.333 206.667z"
      />
      <path fill="#434a2c" stroke="none" d="M449 206L449 209L450 209L449 206z" />
      <path
        fill="#507641"
        stroke="none"
        d="M117 207L118 208L117 207M152 207L153 208L152 207M200 207L201 208L200 207z"
      />
      <path fill="#434a2c" stroke="none" d="M267 207L268 208L267 207M318 207L319 208L318 207z" />
      <path fill="#507641" stroke="none" d="M347 207L348 208L347 207M199 208L200 209L199 208z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M266 208L267 209L266 208M317 208L318 209L317 208M347.333 208.667L347.667 209.333L347.333 208.667M412 208L413 209L412 208z"
      />
      <path fill="#507641" stroke="none" d="M116 209L117 210L116 209z" />
      <path fill="#434a2c" stroke="none" d="M151 209L152 210L151 209z" />
      <path fill="#507641" stroke="none" d="M198 209L199 210L198 209z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M265 209L266 210L265 209M316 209L317 210L316 209M384 209L385 210L384 209M115 210L116 211L115 210z"
      />
      <path fill="#507641" stroke="none" d="M150 210L151 211L150 210z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M197 210L198 211L197 210M264 210L265 211L264 210M315 210L316 211L315 210z"
      />
      <path fill="#507641" stroke="none" d="M384.333 210.667L384.667 211.333L384.333 210.667z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M196 211L197 212L196 211M263 211L264 212L263 211M314 211L315 212L314 211z"
      />
      <path
        fill="#507641"
        stroke="none"
        d="M346 211L347 212L346 211M411.333 211.667L411.667 212.333L411.333 211.667M114 212L115 213L114 212z"
      />
      <path
        fill="#434a2c"
        stroke="none"
        d="M195 212L196 213L195 212M262 212L263 213L262 212M313 212L314 213L313 212M346 212L347 213L346 212M448 212L448 215L449 215L448 212M148 213L149 214L148 213M194 213L195 214L194 213M261 213L262 214L261 213M312 213L313 214L312 213M383.333 213.667L383.667 214.333L383.333 213.667M411 213L412 214L411 213z"
      />
      <path fill="#507641" stroke="none" d="M113 214L114 215L113 214M147 214L148 215L147 214z" />
      <path fill="#434a2c" stroke="none" d="M260 214L261 215L260 214M311 214L312 215L311 214z" />
      <path fill="#507641" stroke="none" d="M345 214L346 215L345 214z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M112 215L113 216L112 215M259 215L260 216L259 215M310 215L311 216L310 215M345 215L346 216L345 215z"
      />
      <path fill="#507641" stroke="none" d="M383 215L384 216L383 215M112 216L113 217L112 216z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M146 216L147 217L146 216M258 216L259 217L258 216M309 216L310 217L309 216z"
      />
      <path fill="#507641" stroke="none" d="M410 216L411 217L410 216z" />
      <path fill="#434a2c" stroke="none" d="M111 217L112 218L111 217z" />
      <path fill="#507641" stroke="none" d="M145 217L146 218L145 217M191 217L192 218L191 217z" />
      <path fill="#434a2c" stroke="none" d="M257 217L258 218L257 217M308 217L309 218L308 217z" />
      <path fill="#507641" stroke="none" d="M344 217L345 218L344 217z" />
      <path fill="#434a2c" stroke="none" d="M382 217L383 218L382 217M410 217L411 218L410 217z" />
      <path fill="#507641" stroke="none" d="M190 218L191 219L190 218z" />
      <path fill="#434a2c" stroke="none" d="M256 218L257 219L256 218M307 218L308 219L307 218z" />
      <path fill="#507641" stroke="none" d="M382 218L383 219L382 218z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M447.333 218.667L447.667 219.333L447.333 218.667M110 219L111 220L110 219M144 219L145 220L144 219M189 219L190 220L189 219M255 219L256 220L255 219M306 219L307 220L306 219z"
      />
      <path fill="#507641" stroke="none" d="M143 220L144 221L143 220z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M188 220L189 221L188 220M254 220L255 221L254 220M305 220L306 221L305 220M343 220L344 221L343 220z"
      />
      <path fill="#507641" stroke="none" d="M409 220L410 221L409 220M109 221L110 222L109 221z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M253 221L254 222L253 221M304 221L305 222L304 221M381 221L382 222L381 221M409 221L410 222L409 221M142 222L143 223L142 222M252 222L253 223L252 222M303 222L304 223L303 222z"
      />
      <path
        fill="#507641"
        stroke="none"
        d="M342 222L343 223L342 222M381 222L382 223L381 222M108 223L109 224L108 223M141 223L142 224L141 223M186 223L187 224L186 223z"
      />
      <path
        fill="#434a2c"
        stroke="none"
        d="M251 223L252 224L251 223M302 223L303 224L302 223M185 224L186 225L185 224M250 224L251 225L250 224M301 224L302 225L301 224M380 224L381 225L380 224z"
      />
      <path fill="#507641" stroke="none" d="M408 224L409 225L408 224M107 225L108 226L107 225z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M140 225L141 226L140 225M184 225L185 226L184 225M249 225L250 226L249 225M300 225L301 226L300 225M341 225L342 226L341 225z"
      />
      <path fill="#507641" stroke="none" d="M380 225L381 226L380 225z" />
      <path fill="#434a2c" stroke="none" d="M408 225L409 226L408 225z" />
      <path fill="#507641" stroke="none" d="M139 226L140 227L139 226z" />
      <path fill="#434a2c" stroke="none" d="M248 226L249 227L248 226M299 226L300 227L299 226z" />
      <path fill="#507641" stroke="none" d="M106 227L107 228L106 227M183 227L184 228L183 227z" />
      <path fill="#434a2c" stroke="none" d="M247 227L248 228L247 227M298 227L299 228L298 227z" />
      <path fill="#507641" stroke="none" d="M340 227L341 228L340 227z" />
      <path fill="#434a2c" stroke="none" d="M379 227L380 228L379 227z" />
      <path
        fill="#507641"
        stroke="none"
        d="M407 227L408 228L407 227M138 228L139 229L138 228M182 228L183 229L182 228z"
      />
      <path
        fill="#434a2c"
        stroke="none"
        d="M246 228L247 229L246 228M297 228L298 229L297 228M407.333 228.667L407.667 229.333L407.333 228.667z"
      />
      <path fill="#507641" stroke="none" d="M105 229L106 230L105 229z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M181 229L182 230L181 229M245 229L246 230L245 229M296 229L297 230L296 229z"
      />
      <path fill="#507641" stroke="none" d="M339 229L340 230L339 229z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M378 229L379 230L378 229M137 230L138 231L137 230M244 230L245 231L244 230M295 230L296 231L295 230z"
      />
      <path
        fill="#507641"
        stroke="none"
        d="M378 230L379 231L378 230M104 231L105 232L104 231M136 231L137 232L136 231M180 231L181 232L180 231z"
      />
      <path fill="#434a2c" stroke="none" d="M243 231L244 232L243 231M294 231L295 232L294 231z" />
      <path fill="#507641" stroke="none" d="M338 231L339 232L338 231M406 231L407 232L406 231z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M136 232L137 233L136 232M179 232L180 233L179 232M242 232L243 233L242 232M293 232L294 233L293 232M338 232L339 233L338 232M377 232L378 233L377 232M406 232L407 233L406 232M103 233L104 234L103 233z"
      />
      <path fill="#507641" stroke="none" d="M135 233L136 234L135 233z" />
      <path fill="#434a2c" stroke="none" d="M241 233L242 234L241 233M292 233L293 234L292 233z" />
      <path fill="#507641" stroke="none" d="M337 233L338 234L337 233M377 233L378 234L377 233z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M240 234L241 235L240 234M291 234L292 235L291 234M337 234L338 235L337 234z"
      />
      <path fill="#507641" stroke="none" d="M405 234L406 235L405 234z" />
      <path fill="#434a2c" stroke="none" d="M102 235L103 236L102 235z" />
      <path fill="#507641" stroke="none" d="M134 235L135 236L134 235M177 235L178 236L177 235z" />
      <path fill="#434a2c" stroke="none" d="M239 235L240 236L239 235M290 235L291 236L290 235z" />
      <path fill="#507641" stroke="none" d="M336 235L337 236L336 235z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M376 235L377 236L376 235M405 235L406 236L405 235M176 236L177 237L176 236M238 236L239 237L238 236M289 236L290 237L289 236M336 236L337 237L336 236M101 237L102 238L101 237M133 237L134 238L133 237M237 237L238 238L237 237M288 237L289 238L288 237z"
      />
      <path fill="#507641" stroke="none" d="M335 237L336 238L335 237z" />
      <path fill="#434a2c" stroke="none" d="M375 237L376 238L375 237z" />
      <path
        fill="#507641"
        stroke="none"
        d="M404 237L405 238L404 237M101 238L102 239L101 238M175 238L176 239L175 238z"
      />
      <path fill="#434a2c" stroke="none" d="M236 238L237 239L236 238M287 238L288 239L287 238z" />
      <path fill="#507641" stroke="none" d="M375 238L376 239L375 238z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M404 238L405 239L404 238M132 239L133 240L132 239M174 239L175 240L174 239M235 239L236 240L235 239M286 239L287 240L286 239z"
      />
      <path fill="#507641" stroke="none" d="M334 239L335 240L334 239M100 240L101 241L100 240z" />
      <path fill="#434a2c" stroke="none" d="M234 240L235 241L234 240M285 240L286 241L285 240z" />
      <path fill="#507641" stroke="none" d="M374 240L375 241L374 240M403 240L404 241L403 240z" />
      <path fill="#434a2c" stroke="none" d="M131 241L132 242L131 241z" />
      <path fill="#507641" stroke="none" d="M173 241L174 242L173 241z" />
      <path fill="#434a2c" stroke="none" d="M233 241L234 242L233 241M284 241L285 242L284 241z" />
      <path fill="#507641" stroke="none" d="M333 241L334 242L333 241z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M99 242L100 243L99 242M172 242L173 243L172 242M232 242L233 243L232 242M283 242L284 243L283 242M373 242L374 243L373 242z"
      />
      <path fill="#507641" stroke="none" d="M402 242L403 243L402 242z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M130 243L131 244L130 243M231 243L232 244L231 243M282 243L283 244L282 243M332 243L333 244L332 243M402 243L403 244L402 243M98 244L99 245L98 244z"
      />
      <path fill="#507641" stroke="none" d="M171 244L172 245L171 244z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M230 244L231 245L230 244M281 244L282 245L281 244M372 244L373 245L372 244z"
      />
      <path fill="#507641" stroke="none" d="M98 245L99 246L98 245z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M129 245L130 246L129 245M229 245L230 246L229 245M280 245L281 246L280 245M331 245L332 246L331 245z"
      />
      <path
        fill="#507641"
        stroke="none"
        d="M401 245L402 246L401 245M128 246L129 247L128 246M170 246L171 247L170 246z"
      />
      <path fill="#434a2c" stroke="none" d="M228 246L229 247L228 246M279 246L280 247L279 246z" />
      <path fill="#507641" stroke="none" d="M330 246L331 247L330 246z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M371 246L372 247L371 246M401 246L402 247L401 246M97 247L98 248L97 247M128 247L129 248L128 247M169 247L170 248L169 247M227 247L228 248L227 247M278 247L279 248L278 247M330 247L331 248L330 247z"
      />
      <path fill="#507641" stroke="none" d="M371 247L372 248L371 247M127 248L128 249L127 248z" />
      <path fill="#434a2c" stroke="none" d="M226 248L227 249L226 248M277 248L278 249L277 248z" />
      <path fill="#507641" stroke="none" d="M329 248L330 249L329 248z" />
      <path fill="#434a2c" stroke="none" d="M370 248L371 249L370 248z" />
      <path fill="#507641" stroke="none" d="M400 248L401 249L400 248z" />
      <path fill="#434a2c" stroke="none" d="M96 249L97 250L96 249M127 249L128 250L127 249z" />
      <path fill="#507641" stroke="none" d="M168 249L169 250L168 249z" />
      <path fill="#434a2c" stroke="none" d="M225 249L226 250L225 249M276 249L277 250L276 249z" />
      <path fill="#507641" stroke="none" d="M370 249L371 250L370 249M96 250L97 251L96 250z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M224 250L225 251L224 250M275 250L276 251L275 250M328 250L329 251L328 250M369 250L370 251L369 250z"
      />
      <path fill="#507641" stroke="none" d="M399 250L400 251L399 250z" />
      <path fill="#434a2c" stroke="none" d="M126 251L127 252L126 251z" />
      <path fill="#507641" stroke="none" d="M167 251L168 252L167 251z" />
      <path fill="#434a2c" stroke="none" d="M223 251L224 252L223 251M274 251L275 252L274 251z" />
      <path fill="#507641" stroke="none" d="M327 251L328 252L327 251M369 251L370 252L369 251z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M399 251L400 252L399 251M95 252L96 253L95 252M166 252L167 253L166 252M222 252L223 253L222 252M273 252L274 253L273 252M368 252L369 253L368 252z"
      />
      <path fill="#507641" stroke="none" d="M95 253L96 254L95 253M125 253L126 254L125 253z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M221 253L222 254L221 253M272 253L273 254L272 253M326 253L327 254L326 253z"
      />
      <path fill="#507641" stroke="none" d="M368 253L369 254L368 253z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M398 253L399 254L398 253M165 254L166 255L165 254M220 254L221 255L220 254M271 254L272 255L271 254M367 254L368 255L367 254M94 255L95 256L94 255z"
      />
      <path fill="#507641" stroke="none" d="M124 255L125 256L124 255z" />
      <path fill="#434a2c" stroke="none" d="M219 255L220 256L219 255M270 255L271 256L270 255z" />
      <path fill="#507641" stroke="none" d="M367 255L368 256L367 255M397 255L398 256L397 255M94 256L95 257L94 256z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M124 256L125 257L124 256M164 256L165 257L164 256M218 256L219 257L218 256M269 256L270 257L269 256M324 256L325 257L324 256M366 256L367 257L366 256M217 257L218 258L217 257M268 257L269 258L268 257z"
      />
      <path fill="#507641" stroke="none" d="M396 257L397 258L396 257z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M93 258L94 259L93 258M123 258L124 259L123 258M163 258L164 259L163 258M216 258L217 259L216 258M267 258L268 259L267 258M365 258L366 259L365 258z"
      />
      <path fill="#507641" stroke="none" d="M93 259L94 260L93 259z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M215 259L216 260L215 259M266 259L267 260L266 259M322 259L323 260L322 259z"
      />
      <path fill="#507641" stroke="none" d="M395 259L396 260L395 259M122 260L123 261L122 260z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M162 260L163 261L162 260M214 260L215 261L214 260M265 260L266 261L265 260z"
      />
      <path fill="#507641" stroke="none" d="M321 260L322 261L321 260z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M364 260L365 261L364 260M395 260L396 261L395 260M92 261L93 262L92 261M213 261L214 262L213 261M264 261L265 262L264 261z"
      />
      <path fill="#507641" stroke="none" d="M92 262L93 263L92 262M121 262L122 263L121 262z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M161 262L162 263L161 262M212 262L213 263L212 262M263 262L264 263L263 262M320 262L321 263L320 262z"
      />
      <path fill="#507641" stroke="none" d="M363 262L364 263L363 262z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M394 262L395 263L394 262M121 263L122 264L121 263M211 263L212 264L211 263M262 263L263 264L262 263z"
      />
      <path fill="#507641" stroke="none" d="M319 263L320 264L319 263z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M91 264L92 265L91 264M160 264L161 265L160 264M210 264L211 265L210 264M261 264L262 265L261 264z"
      />
      <path fill="#507641" stroke="none" d="M362 264L363 265L362 264z" />
      <path fill="#434a2c" stroke="none" d="M393 264L394 265L393 264z" />
      <path fill="#507641" stroke="none" d="M91 265L92 266L91 265M120 265L121 266L120 265z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M209 265L210 266L209 265M260 265L261 266L260 265M361 265L362 266L361 265M120 266L121 267L120 266M159 266L160 267L159 266M208 266L209 267L208 266M259 266L260 267L259 266M317 266L318 267L317 266M392 266L393 267L392 266z"
      />
      <path fill="#507641" stroke="none" d="M159 267L160 268L159 267z" />
      <path fill="#434a2c" stroke="none" d="M207 267L208 268L207 267M258 267L259 268L258 267z" />
      <path fill="#507641" stroke="none" d="M316 267L317 268L316 267M360 267L361 268L360 267z" />
      <path fill="#434a2c" stroke="none" d="M90 268L91 269L90 268z" />
      <path fill="#507641" stroke="none" d="M119 268L120 269L119 268z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M206 268L207 269L206 268M257 268L258 269L257 268M359 268L360 269L359 268M391 268L392 269L391 268z"
      />
      <path fill="#507641" stroke="none" d="M90 269L91 270L90 269z" />
      <path fill="#434a2c" stroke="none" d="M119 269L120 270L119 269z" />
      <path fill="#507641" stroke="none" d="M158 269L159 270L158 269z" />
      <path fill="#434a2c" stroke="none" d="M205 269L206 270L205 269M256 269L257 270L256 269z" />
      <path fill="#507641" stroke="none" d="M359 269L360 270L359 269z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M204 270L205 271L204 270M255 270L256 271L255 270M314 270L315 271L314 270M358 270L359 271L358 270M390 270L391 271L390 270M89 271L90 272L89 271z"
      />
      <path fill="#507641" stroke="none" d="M118 271L119 272L118 271z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M157 271L158 272L157 271M203 271L204 272L203 271M254 271L255 272L254 271z"
      />
      <path
        fill="#507641"
        stroke="none"
        d="M313 271L314 272L313 271M389 271L390 272L389 271M89.3333 272.667L89.6667 273.333L89.3333 272.667z"
      />
      <path
        fill="#434a2c"
        stroke="none"
        d="M118 272L119 273L118 272M202 272L203 273L202 272M253 272L254 273L253 272z"
      />
      <path fill="#507641" stroke="none" d="M312 272L313 273L312 272M357 272L358 273L357 272z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M156 273L157 274L156 273M201 273L202 274L201 273M252 273L253 274L252 273M356 273L357 274L356 273z"
      />
      <path
        fill="#507641"
        stroke="none"
        d="M388 273L389 274L388 273M117 274L118 275L117 274M156 274L157 275L156 274z"
      />
      <path
        fill="#434a2c"
        stroke="none"
        d="M200 274L201 275L200 274M251 274L252 275L251 274M88.3333 275.667L88.6667 276.333L88.3333 275.667M117 275L118 276L117 275M199 275L200 276L199 275M250 275L251 276L250 275M310 275L311 276L310 275z"
      />
      <path
        fill="#507641"
        stroke="none"
        d="M355 275L356 276L355 275M387 275L388 276L387 275M155 276L156 277L155 276z"
      />
      <path
        fill="#434a2c"
        stroke="none"
        d="M198 276L199 277L198 276M249 276L250 277L249 276M309 276L310 277L309 276M354 276L355 277L354 276z"
      />
      <path fill="#507641" stroke="none" d="M88 277L89 278L88 277z" />
      <path fill="#434a2c" stroke="none" d="M197 277L198 278L197 277M248 277L249 278L248 277z" />
      <path fill="#507641" stroke="none" d="M308 277L309 278L308 277z" />
      <path fill="#434a2c" stroke="none" d="M386 277L387 278L386 277z" />
      <path fill="#507641" stroke="none" d="M116 278L117 279L116 278z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M154 278L155 279L154 278M196 278L197 279L196 278M247 278L248 279L247 278z"
      />
      <path fill="#507641" stroke="none" d="M307 278L308 279L307 278M353 278L354 279L353 278z" />
      <path fill="#434a2c" stroke="none" d="M116 279L117 280L116 279z" />
      <path fill="#507641" stroke="none" d="M154 279L155 280L154 279z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M195 279L196 280L195 279M246 279L247 280L246 279M352 279L353 280L352 279M385 279L386 280L385 279M87 280L88 281L87 280M194 280L195 281L194 280M245 280L246 281L245 280z"
      />
      <path
        fill="#507641"
        stroke="none"
        d="M384 280L385 281L384 280M87.3333 281.667L87.6667 282.333L87.3333 281.667M115.333 281.667L115.667 282.333L115.333 281.667z"
      />
      <path
        fill="#434a2c"
        stroke="none"
        d="M153 281L154 282L153 281M193 281L194 282L193 281M244 281L245 282L244 281M305 281L306 282L305 281z"
      />
      <path fill="#507641" stroke="none" d="M351 281L352 282L351 281M153 282L154 283L153 282z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M192 282L193 283L192 282M243 282L244 283L243 282M304 282L305 283L304 282M350 282L351 283L350 282z"
      />
      <path fill="#507641" stroke="none" d="M383 282L384 283L383 282z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M115 283L116 284L115 283M191 283L192 284L191 283M242 283L243 284L242 283M303 283L304 284L303 283z"
      />
      <path fill="#507641" stroke="none" d="M49.3333 284.667L49.6667 285.333L49.3333 284.667z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M152 284L153 285L152 284M190 284L191 285L190 284M241 284L242 285L241 284M302 284L303 285L302 284M382 284L383 285L382 284M86.3333 285.667L86.6667 286.333L86.3333 285.667z"
      />
      <path
        fill="#507641"
        stroke="none"
        d="M114.333 285.667L114.667 286.333L114.333 285.667M152 285L153 286L152 285z"
      />
      <path fill="#434a2c" stroke="none" d="M189 285L190 286L189 285M240 285L241 286L240 285z" />
      <path
        fill="#507641"
        stroke="none"
        d="M301 285L302 286L301 285M348 285L349 286L348 285M381 285L382 286L381 285z"
      />
      <path
        fill="#434a2c"
        stroke="none"
        d="M49 286L49 289L50 289L49 286M188 286L189 287L188 286M239 286L240 287L239 286z"
      />
      <path fill="#507641" stroke="none" d="M300 286L301 287L300 286z" />
      <path fill="#434a2c" stroke="none" d="M347 286L348 287L347 286z" />
      <path fill="#507641" stroke="none" d="M86 287L87 288L86 287z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M114 287L115 288L114 287M187 287L188 288L187 287M238 287L239 288L238 287z"
      />
      <path fill="#507641" stroke="none" d="M299 287L300 288L299 287M380 287L381 288L380 287z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M151 288L152 289L151 288M186 288L187 289L186 288M237 288L238 289L237 288z"
      />
      <path fill="#507641" stroke="none" d="M298 288L299 289L298 288M151 289L152 290L151 289z" />
      <path fill="#434a2c" stroke="none" d="M185 289L186 290L185 289M236 289L237 290L236 289z" />
      <path fill="#507641" stroke="none" d="M297 289L298 290L297 289M345 289L346 290L345 289z" />
      <path fill="#434a2c" stroke="none" d="M379 289L380 290L379 289z" />
      <path fill="#49ba82" stroke="none" d="M48 290L49 291L48 290z" />
      <path fill="#507641" stroke="none" d="M113.333 290.667L113.667 291.333L113.333 290.667z" />
      <path fill="#434a2c" stroke="none" d="M184 290L185 291L184 290M235 290L236 291L235 290z" />
      <path fill="#507641" stroke="none" d="M296 290L297 291L296 290z" />
      <path fill="#434a2c" stroke="none" d="M344 290L345 291L344 290z" />
      <path
        fill="#507641"
        stroke="none"
        d="M378 290L379 291L378 290M48.3333 291.667L48.6667 292.333L48.3333 291.667z"
      />
      <path
        fill="#434a2c"
        stroke="none"
        d="M85.3333 291.667L85.6667 292.333L85.3333 291.667M183 291L184 292L183 291M234 291L235 292L234 291z"
      />
      <path fill="#507641" stroke="none" d="M295 291L296 292L295 291z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M343 291L344 292L343 291M113 292L114 293L113 292M150.333 292.667L150.667 293.333L150.333 292.667M182 292L183 293L182 292M233 292L234 293L233 292z"
      />
      <path fill="#507641" stroke="none" d="M294 292L295 293L294 292z" />
      <path fill="#434a2c" stroke="none" d="M377 292L378 293L377 292M48 293L48 298L49 298L48 293z" />
      <path fill="#507641" stroke="none" d="M85.3333 293.667L85.6667 294.333L85.3333 293.667z" />
      <path fill="#434a2c" stroke="none" d="M181 293L182 294L181 293M232 293L233 294L232 293z" />
      <path
        fill="#507641"
        stroke="none"
        d="M293 293L294 294L293 293M342 293L343 294L342 293M376 293L377 294L376 293M150 294L151 295L150 294z"
      />
      <path fill="#434a2c" stroke="none" d="M180 294L181 295L180 294M231 294L232 295L231 294z" />
      <path fill="#507641" stroke="none" d="M292 294L293 295L292 294M341 294L342 295L341 294z" />
      <path fill="#434a2c" stroke="none" d="M179 295L180 296L179 295M230 295L231 296L230 295z" />
      <path fill="#507641" stroke="none" d="M291 295L292 296L291 295z" />
      <path fill="#434a2c" stroke="none" d="M340 295L341 296L340 295M375 295L376 296L375 295z" />
      <path fill="#507641" stroke="none" d="M112.333 296.667L112.667 297.333L112.333 296.667z" />
      <path fill="#434a2c" stroke="none" d="M178 296L179 297L178 296M229 296L230 297L229 296z" />
      <path fill="#507641" stroke="none" d="M290 296L291 297L290 296z" />
      <path fill="#434a2c" stroke="none" d="M339 296L340 297L339 296z" />
      <path fill="#507641" stroke="none" d="M374 296L375 297L374 296z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M149 297L150 298L149 297M177 297L178 298L177 297M228 297L229 298L228 297z"
      />
      <path fill="#507641" stroke="none" d="M289 297L290 298L289 297z" />
      <path fill="#434a2c" stroke="none" d="M112.333 298.667L112.667 299.333L112.333 298.667z" />
      <path fill="#507641" stroke="none" d="M149.333 298.667L149.667 299.333L149.333 298.667z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M176 298L177 299L176 298M227 298L228 299L227 298M288 298L289 299L288 298z"
      />
      <path fill="#507641" stroke="none" d="M338 298L339 299L338 298z" />
      <path fill="#434a2c" stroke="none" d="M373 298L374 299L373 298z" />
      <path fill="#49ba82" stroke="none" d="M47 299L48 300L47 299z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M84.3333 299.667L84.6667 300.333L84.3333 299.667M175 299L176 300L175 299M226 299L227 300L226 299M287 299L288 300L287 299z"
      />
      <path
        fill="#507641"
        stroke="none"
        d="M337 299L338 300L337 299M372 299L373 300L372 299M47 300L47 304L48 304L47 300z"
      />
      <path
        fill="#434a2c"
        stroke="none"
        d="M174 300L175 301L174 300M225 300L226 301L225 300M286 300L287 301L286 300M336 300L337 301L336 300M415 300L416 301L415 300z"
      />
      <path fill="#507641" stroke="none" d="M84 301L84 304L85 304L84 301z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M173 301L174 302L173 301M224 301L225 302L224 301M285 301L286 302L285 301M335 301L336 302L335 301M148 302L148 305L149 305L148 302M172 302L173 303L172 302M223 302L224 303L223 302M370 302L371 303L370 302M414 302L413 304L414 302M171 303L172 304L171 303M222 303L223 304L222 303z"
      />
      <path fill="#507641" stroke="none" d="M369 303L370 304L369 303z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M47 304L47 330L48 330L47 304M170 304L171 305L170 304M221 304L222 305L221 304z"
      />
      <path
        fill="#507641"
        stroke="none"
        d="M281 304L282 305L281 304M333 304L334 305L333 304M111 305L111 310L112 310L111 305M148.333 305.667L148.667 306.333L148.333 305.667z"
      />
      <path fill="#434a2c" stroke="none" d="M169 305L170 306L169 305M220 305L221 306L220 305z" />
      <path fill="#507641" stroke="none" d="M280 305L281 306L280 305M332 305L333 306L332 305z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M368 305L369 306L368 305M412 305L410 309L411 309L412 305M168 306L169 307L168 306M219 306L220 307L219 306z"
      />
      <path
        fill="#507641"
        stroke="none"
        d="M279 306L280 307L279 306M331 306L332 307L331 306M367 306L368 307L367 306z"
      />
      <path
        fill="#434a2c"
        stroke="none"
        d="M167 307L168 308L167 307M218 307L219 308L218 307M278 307L279 308L278 307M330 307L331 308L330 307z"
      />
      <path fill="#507641" stroke="none" d="M366 307L367 308L366 307z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M166 308L167 309L166 308M217 308L218 309L217 308M277 308L278 309L277 308M329 308L330 309L329 308M165 309L166 310L165 309M216 309L217 310L216 309M328 309L329 310L328 309M111 310L111 319L112 319L111 310M164 310L165 311L164 310M215 310L216 311L215 310z"
      />
      <path fill="#507641" stroke="none" d="M274 310L275 311L274 310z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M327 310L328 311L327 310M364 310L365 311L364 310M409 310L408 312L409 310M83 311L83 323L84 323L83 311M163 311L164 312L163 311M214 311L215 312L214 311z"
      />
      <path fill="#507641" stroke="none" d="M273 311L274 312L273 311z" />
      <path fill="#434a2c" stroke="none" d="M326 311L327 312L326 311z" />
      <path fill="#507641" stroke="none" d="M363 311L364 312L363 311z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M162 312L163 313L162 312M213 312L214 313L213 312M272 312L273 313L272 312M161 313L162 314L161 313M212 313L213 314L212 313M407 313L406 315L407 313M160 314L161 315L160 314M211 314L212 315L211 314z"
      />
      <path fill="#507641" stroke="none" d="M269 314L270 315L269 314z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M361 314L362 315L361 314M159 315L160 316L159 315M210 315L211 316L210 315M268 315L269 316L268 315z"
      />
      <path fill="#507641" stroke="none" d="M360 315L361 316L360 315z" />
      <path fill="#434a2c" stroke="none" d="M158 316L159 317L158 316M209 316L210 317L209 316z" />
      <path fill="#507641" stroke="none" d="M322 316L323 317L322 316M359 316L360 317L359 316z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M405 316L404 318L405 316M157 317L158 318L157 317M208 317L209 318L208 317z"
      />
      <path fill="#507641" stroke="none" d="M265 317L266 318L265 317M321 317L322 318L321 317z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M156 318L157 319L156 318M207 318L208 319L207 318M264 318L265 319L264 318z"
      />
      <path fill="#507641" stroke="none" d="M320 318L321 319L320 318M111 319L111 323L112 323L111 319z" />
      <path fill="#434a2c" stroke="none" d="M155 319L156 320L155 319M206 319L207 320L206 319z" />
      <path fill="#507641" stroke="none" d="M262 319L263 320L262 319M319 319L320 320L319 319z" />
      <path fill="#434a2c" stroke="none" d="M403 319L402 321L403 319z" />
      <path fill="#507641" stroke="none" d="M148.333 320.667L148.667 321.333L148.333 320.667z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M154 320L155 321L154 320M205 320L206 321L205 320M261 320L262 321L261 320z"
      />
      <path fill="#507641" stroke="none" d="M318 320L319 321L318 320z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M356 320L357 321L356 320M153 321L154 322L153 321M204 321L205 322L204 321z"
      />
      <path fill="#507641" stroke="none" d="M259 321L260 322L259 321M317 321L318 322L317 321z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M355 321L356 322L355 321M148.333 322.667L148.667 323.333L148.333 322.667M152 322L153 323L152 322M203 322L204 323L203 322M258 322L259 323L258 322z"
      />
      <path fill="#507641" stroke="none" d="M316 322L317 323L316 322M354 322L355 323L354 322z" />
      <path fill="#434a2c" stroke="none" d="M151 323L152 324L151 323M202 323L203 324L202 323z" />
      <path
        fill="#507641"
        stroke="none"
        d="M256 323L257 324L256 323M315 323L316 324L315 323M353 323L354 324L353 323z"
      />
      <path
        fill="#434a2c"
        stroke="none"
        d="M400 323L399 325L400 323M150 324L151 325L150 324M201 324L202 325L201 324M255 324L256 325L255 324M200 325L201 326L200 325z"
      />
      <path fill="#507641" stroke="none" d="M253 325L254 326L253 325z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M112.333 326.667L112.667 327.333L112.333 326.667M199 326L200 327L199 326M252 326L253 327L252 326M398 326L397 328L398 326M198 327L199 328L198 327M250 327L251 328L250 327M310 327L311 328L310 327M350 327L351 328L350 327z"
      />
      <path fill="#507641" stroke="none" d="M112.333 328.667L112.667 329.333L112.333 328.667z" />
      <path fill="#434a2c" stroke="none" d="M197 328L198 329L197 328z" />
      <path fill="#507641" stroke="none" d="M248 328L249 329L248 328z" />
      <path fill="#434a2c" stroke="none" d="M309 328L310 329L309 328M349 328L350 329L349 328z" />
      <path fill="#507641" stroke="none" d="M84 329L84 332L85 332L84 329z" />
      <path fill="#434a2c" stroke="none" d="M196 329L197 330L196 329z" />
      <path fill="#507641" stroke="none" d="M246 329L247 330L246 329z" />
      <path fill="#434a2c" stroke="none" d="M308 329L309 330L308 329M348 329L349 330L348 329z" />
      <path fill="#507641" stroke="none" d="M47 330L47 333L48 333L47 330z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M195 330L196 331L195 330M245 330L246 331L245 330M307 330L308 331L307 330z"
      />
      <path fill="#507641" stroke="none" d="M347 330L348 331L347 330z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M395 330L393 333L395 330M194 331L195 332L194 331M243 331L244 332L243 331z"
      />
      <path fill="#507641" stroke="none" d="M306 331L307 332L306 331M346 331L347 332L346 331z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M84 332L84 335L85 335L84 332M113.333 332.667L113.667 333.333L113.333 332.667M193 332L194 333L193 332M241 332L242 333L241 332z"
      />
      <path fill="#507641" stroke="none" d="M305 332L306 333L305 332M345 332L346 333L345 332z" />
      <path fill="#49ba82" stroke="none" d="M47.3333 333.667L47.6667 334.333L47.3333 333.667z" />
      <path fill="#434a2c" stroke="none" d="M192 333L193 334L192 333M239 333L240 334L239 333z" />
      <path
        fill="#507641"
        stroke="none"
        d="M304 333L305 334L304 333M344 333L345 334L344 333M113 334L114 335L113 334z"
      />
      <path
        fill="#434a2c"
        stroke="none"
        d="M191 334L192 335L191 334M237 334L238 335L237 334M302 334L303 335L302 334z"
      />
      <path fill="#507641" stroke="none" d="M343 334L344 335L343 334z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M392 334L389 338L392 334M48 335L48 340L49 340L48 335M190 335L191 336L190 335M235 335L236 336L235 335M301 335L302 336L301 335z"
      />
      <path fill="#507641" stroke="none" d="M342 335L343 336L342 335z" />
      <path fill="#434a2c" stroke="none" d="M189 336L190 337L189 336M233 336L234 337L233 336z" />
      <path fill="#507641" stroke="none" d="M300 336L301 337L300 336M341 336L342 337L341 336z" />
      <path fill="#434a2c" stroke="none" d="M114 337L115 338L114 337M188 337L189 338L188 337z" />
      <path fill="#507641" stroke="none" d="M230 337L231 338L230 337z" />
      <path fill="#434a2c" stroke="none" d="M231 337L232 338L231 337z" />
      <path
        fill="#507641"
        stroke="none"
        d="M299 337L300 338L299 337M85.3333 338.667L85.6667 339.333L85.3333 338.667M114 338L115 339L114 338z"
      />
      <path fill="#434a2c" stroke="none" d="M187 338L188 339L187 338z" />
      <path fill="#507641" stroke="none" d="M228 338L229 339L228 338z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M229 338L230 339L229 338M297 338L298 339L297 338M186 339L187 340L186 339z"
      />
      <path fill="#507641" stroke="none" d="M226 339L227 340L226 339z" />
      <path fill="#434a2c" stroke="none" d="M296 339L297 340L296 339M388 339L385 343L388 339z" />
      <path fill="#507641" stroke="none" d="M48.3333 340.667L48.6667 341.333L48.3333 340.667z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M85 340L86 341L85 340M115 340L116 341L115 340M185 340L186 341L185 340M224 340L225 341L224 340z"
      />
      <path fill="#507641" stroke="none" d="M295 340L296 341L295 340M115 341L116 342L115 341z" />
      <path fill="#434a2c" stroke="none" d="M184 341L185 342L184 341z" />
      <path fill="#507641" stroke="none" d="M221 341L222 342L221 341z" />
      <path fill="#434a2c" stroke="none" d="M222 341L223 342L222 341z" />
      <path fill="#507641" stroke="none" d="M336 341L337 342L336 341z" />
      <path fill="#49ba82" stroke="none" d="M48 342L49 343L48 342z" />
      <path fill="#434a2c" stroke="none" d="M183 342L184 343L183 342z" />
      <path fill="#507641" stroke="none" d="M218 342L219 343L218 342z" />
      <path fill="#434a2c" stroke="none" d="M219 342L220 343L219 342M292 342L293 343L292 342z" />
      <path fill="#507641" stroke="none" d="M335 342L336 343L335 342z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M49 343L49 346L50 346L49 343M116 343L117 344L116 343M182 343L183 344L182 343z"
      />
      <path fill="#507641" stroke="none" d="M215 343L216 344L215 343z" />
      <path fill="#434a2c" stroke="none" d="M216 343L217 344L216 343z" />
      <path
        fill="#507641"
        stroke="none"
        d="M291 343L292 344L291 343M334 343L335 344L334 343M86 344L87 345L86 344M116 344L117 345L116 344z"
      />
      <path fill="#434a2c" stroke="none" d="M181 344L182 345L181 344z" />
      <path fill="#507641" stroke="none" d="M212 344L213 345L212 344z" />
      <path fill="#434a2c" stroke="none" d="M213 344L214 345L213 344z" />
      <path fill="#507641" stroke="none" d="M290 344L291 345L290 344M333 344L334 345L333 344z" />
      <path fill="#434a2c" stroke="none" d="M86 345L87 346L86 345M180 345L181 346L180 345z" />
      <path fill="#507641" stroke="none" d="M208 345L209 346L208 345z" />
      <path fill="#434a2c" stroke="none" d="M209 345L210 346L209 345M288 345L289 346L288 345z" />
      <path fill="#507641" stroke="none" d="M332 345L333 346L332 345z" />
      <path fill="#434a2c" stroke="none" d="M383 345L378 351L383 345z" />
      <path
        fill="#507641"
        stroke="none"
        d="M49.3333 346.667L49.6667 347.333L49.3333 346.667M117 346L118 347L117 346z"
      />
      <path fill="#434a2c" stroke="none" d="M179 346L180 347L179 346z" />
      <path fill="#507641" stroke="none" d="M205 346L206 347L205 346z" />
      <path fill="#434a2c" stroke="none" d="M206 346L207 347L206 346M287 346L288 347L287 346z" />
      <path fill="#507641" stroke="none" d="M331 346L332 347L331 346z" />
      <path fill="#434a2c" stroke="none" d="M178 347L179 348L178 347z" />
      <path fill="#507641" stroke="none" d="M200.667 347.333L201.333 347.667L200.667 347.333z" />
      <path fill="#434a2c" stroke="none" d="M202 347L203 348L202 347z" />
      <path fill="#507641" stroke="none" d="M286 347L287 348L286 347z" />
      <path fill="#434a2c" stroke="none" d="M330 347L331 348L330 347z" />
      <path fill="#49ba82" stroke="none" d="M49 348L50 349L49 348z" />
      <path fill="#507641" stroke="none" d="M87 348L88 349L87 348z" />
      <path fill="#434a2c" stroke="none" d="M118 348L119 349L118 348M177 348L178 349L177 348z" />
      <path fill="#507641" stroke="none" d="M194.667 348.333L195.333 348.667L194.667 348.333z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M196.667 348.333L197.333 348.667L196.667 348.333M284 348L285 349L284 348M329 348L330 349L329 348M87 349L88 350L87 349M179 349L179 350L183 350L179 349z"
      />
      <path fill="#507641" stroke="none" d="M183 349L184 350L183 349z" />
      <path fill="#434a2c" stroke="none" d="M184 349L184 350L190 350L184 349z" />
      <path fill="#507641" stroke="none" d="M283 349L284 350L283 349z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M328 349L329 350L328 349M50.3333 350.667L50.6667 351.333L50.3333 350.667M119 350L120 351L119 350M281 350L282 351L281 350z"
      />
      <path fill="#507641" stroke="none" d="M280 351L281 352L280 351M50 352L51 353L50 352M88 352L89 353L88 352z" />
      <path fill="#434a2c" stroke="none" d="M120 352L121 353L120 352M278 352L279 353L278 352z" />
      <path fill="#507641" stroke="none" d="M324 352L325 353L324 352z" />
      <path fill="#49ba82" stroke="none" d="M50 353L51 354L50 353z" />
      <path fill="#434a2c" stroke="none" d="M88 353L89 354L88 353z" />
      <path fill="#507641" stroke="none" d="M277 353L278 354L277 353M323 353L324 354L323 353z" />
      <path fill="#434a2c" stroke="none" d="M51.3333 354.667L51.6667 355.333L51.3333 354.667z" />
      <path fill="#507641" stroke="none" d="M121 354L122 355L121 354z" />
      <path fill="#434a2c" stroke="none" d="M275 354L276 355L275 354M322 354L323 355L322 354z" />
      <path fill="#507641" stroke="none" d="M89 355L90 356L89 355z" />
      <path fill="#434a2c" stroke="none" d="M122 355L123 356L122 355z" />
      <path fill="#507641" stroke="none" d="M274 355L275 356L274 355z" />
      <path fill="#434a2c" stroke="none" d="M321 355L322 356L321 355z" />
      <path fill="#507641" stroke="none" d="M51 356L52 357L51 356z" />
      <path fill="#434a2c" stroke="none" d="M89 356L90 357L89 356M272 356L273 357L272 356z" />
      <path fill="#49ba82" stroke="none" d="M51 357L52 358L51 357z" />
      <path
        fill="#507641"
        stroke="none"
        d="M123 357L124 358L123 357M271 357L272 358L271 357M318 357L319 358L318 357z"
      />
      <path fill="#434a2c" stroke="none" d="M52.3333 358.667L52.6667 359.333L52.3333 358.667z" />
      <path fill="#507641" stroke="none" d="M90 358L91 359L90 358z" />
      <path fill="#434a2c" stroke="none" d="M124 358L125 359L124 358z" />
      <path fill="#507641" stroke="none" d="M269 358L270 359L269 358M317 358L318 359L317 358z" />
      <path fill="#434a2c" stroke="none" d="M90 359L91 360L90 359M267 359L268 360L267 359M316 359L317 360L316 359z" />
      <path fill="#507641" stroke="none" d="M52 360L53 361L52 360M125 360L126 361L125 360z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M265 360L266 361L265 360M315 360L316 361L315 360M52 361L53 362L52 361M91 361L92 362L91 361M126 361L127 362L126 361z"
      />
      <path
        fill="#507641"
        stroke="none"
        d="M264 361L265 362L264 361M313 361L314 362L313 361M262 362L263 363L262 362M312 362L313 363L312 362M92 363L93 364L92 363z"
      />
      <path
        fill="#434a2c"
        stroke="none"
        d="M260 363L261 364L260 363M311 363L312 364L311 363M92 364L93 365L92 364M258 364L259 365L258 364M256 365L257 366L256 365z"
      />
      <path fill="#507641" stroke="none" d="M308 365L309 366L308 365z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M93 366L94 367L93 366M254 366L255 367L254 366M307 366L308 367L307 366M132 367L133 368L132 367M252 367L253 368L252 367z"
      />
      <path fill="#507641" stroke="none" d="M305 367L306 368L305 367z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M94 368L95 369L94 368M133 368L134 369L133 368M250 368L251 369L250 368M304 368L305 369L304 368M134 369L135 370L134 369M248 369L249 370L248 369z"
      />
      <path fill="#507641" stroke="none" d="M302 369L303 370L302 369z" />
      <path fill="#434a2c" stroke="none" d="M95 370L96 371L95 370z" />
      <path fill="#507641" stroke="none" d="M135 370L136 371L135 370M246 370L247 371L246 370z" />
      <path fill="#434a2c" stroke="none" d="M301 370L302 371L301 370z" />
      <path fill="#507641" stroke="none" d="M96 371L97 372L96 371M136 371L137 372L136 371z" />
      <path fill="#434a2c" stroke="none" d="M243 371L244 372L243 371z" />
      <path fill="#507641" stroke="none" d="M244 371L245 372L244 371z" />
      <path fill="#434a2c" stroke="none" d="M138 372L139 373L138 372M241 372L242 373L241 372z" />
      <path
        fill="#507641"
        stroke="none"
        d="M242 372L243 373L242 372M298 372L299 373L298 372M97 373L98 374L97 373M139 373L140 374L139 373z"
      />
      <path
        fill="#434a2c"
        stroke="none"
        d="M239 373L240 374L239 373M141 374L142 375L141 374M236 374L237 375L236 374z"
      />
      <path fill="#507641" stroke="none" d="M237 374L238 375L237 374z" />
      <path fill="#434a2c" stroke="none" d="M295 374L296 375L295 374M98 375L99 376L98 375z" />
      <path fill="#507641" stroke="none" d="M142 375L143 376L142 375z" />
      <path fill="#434a2c" stroke="none" d="M234 375L235 376L234 375z" />
      <path fill="#507641" stroke="none" d="M235 375L236 376L235 375M99 376L100 377L99 376z" />
      <path fill="#434a2c" stroke="none" d="M144 376L145 377L144 376M231 376L232 377L231 376z" />
      <path fill="#507641" stroke="none" d="M232 376L233 377L232 376z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M292 376L293 377L292 376M146 377L147 378L146 377M228 377L229 378L228 377z"
      />
      <path fill="#507641" stroke="none" d="M229 377L230 378L229 377M290 377L291 378L290 377z" />
      <path fill="#434a2c" stroke="none" d="M100 378L101 379L100 378z" />
      <path fill="#507641" stroke="none" d="M147 378L148 379L147 378z" />
      <path fill="#434a2c" stroke="none" d="M148 378L149 379L148 378z" />
      <path fill="#507641" stroke="none" d="M226 378L227 379L226 378z" />
      <path fill="#434a2c" stroke="none" d="M289 378L290 379L289 378z" />
      <path fill="#507641" stroke="none" d="M101 379L102 380L101 379z" />
      <path fill="#434a2c" stroke="none" d="M150 379L151 380L150 379M222 379L223 380L222 379z" />
      <path fill="#507641" stroke="none" d="M223 379L224 380L223 379z" />
      <path fill="#434a2c" stroke="none" d="M287 379L288 380L287 379z" />
      <path fill="#507641" stroke="none" d="M152 380L153 381L152 380z" />
      <path fill="#434a2c" stroke="none" d="M218 380L219 381L218 380z" />
      <path
        fill="#507641"
        stroke="none"
        d="M219 380L220 381L219 380M285 380L286 381L285 380M154 381L155 382L154 381z"
      />
      <path fill="#434a2c" stroke="none" d="M155 381L156 382L155 381M214 381L215 382L214 381z" />
      <path fill="#507641" stroke="none" d="M215.667 381.333L216.333 381.667L215.667 381.333z" />
      <path fill="#434a2c" stroke="none" d="M284 381L285 382L284 381M103 382L104 383L103 382z" />
      <path fill="#507641" stroke="none" d="M158 382L159 383L158 382z" />
      <path fill="#434a2c" stroke="none" d="M159 382L160 383L159 382M210 382L211 383L210 382z" />
      <path fill="#507641" stroke="none" d="M211 382L212 383L211 382z" />
      <path fill="#434a2c" stroke="none" d="M282 382L283 383L282 382M104 383L105 384L104 383z" />
      <path fill="#507641" stroke="none" d="M161 383L162 384L161 383z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M162.667 383.333L163.333 383.667L162.667 383.333M205.667 383.333L206.333 383.667L205.667 383.333z"
      />
      <path
        fill="#507641"
        stroke="none"
        d="M207 383L208 384L207 383M280 383L281 384L280 383M105 384L106 385L105 384M166 384L167 385L166 384z"
      />
      <path
        fill="#434a2c"
        stroke="none"
        d="M167.667 384.333L168.333 384.667L167.667 384.333M199.667 384.333L200.333 384.667L199.667 384.333z"
      />
      <path
        fill="#507641"
        stroke="none"
        d="M201.667 384.333L202.333 384.667L201.667 384.333M278 384L279 385L278 384M106 385L107 386L106 385M172.667 385.333L173.333 385.667L172.667 385.333z"
      />
      <path fill="#434a2c" stroke="none" d="M174 385L174 386L178 386L174 385M186 385L186 386L191 386L186 385z" />
      <path fill="#507641" stroke="none" d="M191 385L191 386L195 386L191 385z" />
      <path fill="#434a2c" stroke="none" d="M277 385L278 386L277 385z" />
      <path fill="#507641" stroke="none" d="M107 386L108 387L107 386z" />
      <path fill="#434a2c" stroke="none" d="M275 386L276 387L275 386z" />
      <path fill="#507641" stroke="none" d="M108 387L109 388L108 387z" />
      <path fill="#434a2c" stroke="none" d="M273 387L274 388L273 387z" />
      <path fill="#507641" stroke="none" d="M109 388L110 389L109 388z" />
      <path fill="#434a2c" stroke="none" d="M271 388L272 389L271 388z" />
      <path fill="#507641" stroke="none" d="M110 389L111 390L110 389z" />
      <path fill="#434a2c" stroke="none" d="M269 389L270 390L269 389z" />
      <path fill="#507641" stroke="none" d="M111 390L112 391L111 390z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M267 390L268 391L267 390M112 391L113 392L112 391M265 391L266 392L265 391M113 392L114 393L113 392z"
      />
      <path fill="#507641" stroke="none" d="M262 392L263 393L262 392z" />
      <path fill="#434a2c" stroke="none" d="M263 392L264 393L263 392z" />
      <path fill="#507641" stroke="none" d="M260 393L261 394L260 393M116 394L117 395L116 394z" />
      <path fill="#434a2c" stroke="none" d="M258 394L259 395L258 394M117 395L118 396L117 395z" />
      <path fill="#507641" stroke="none" d="M255 395L256 396L255 395z" />
      <path fill="#434a2c" stroke="none" d="M256 395L257 396L256 395z" />
      <path fill="#507641" stroke="none" d="M119 396L120 397L119 396M253 396L254 397L253 396z" />
      <path fill="#434a2c" stroke="none" d="M120 397L121 398L120 397z" />
      <path fill="#507641" stroke="none" d="M250 397L251 398L250 397z" />
      <path fill="#434a2c" stroke="none" d="M251 397L252 398L251 397z" />
      <path
        fill="#507641"
        stroke="none"
        d="M122 398L123 399L122 398M248 398L249 399L248 398M124 399L125 400L124 399M245 399L246 400L245 399z"
      />
      <path
        fill="#434a2c"
        stroke="none"
        d="M246 399L247 400L246 399M125 400L126 401L125 400M243 400L244 401L243 400M127 401L128 402L127 401z"
      />
      <path fill="#507641" stroke="none" d="M240 401L241 402L240 401z" />
      <path fill="#434a2c" stroke="none" d="M241 401L242 402L241 401M129 402L130 403L129 402z" />
      <path fill="#507641" stroke="none" d="M237 402L238 403L237 402z" />
      <path fill="#434a2c" stroke="none" d="M238 402L239 403L238 402M131 403L132 404L131 403z" />
      <path fill="#507641" stroke="none" d="M234 403L235 404L234 403z" />
      <path fill="#434a2c" stroke="none" d="M235 403L236 404L235 403M133 404L134 405L133 404z" />
      <path fill="#507641" stroke="none" d="M134 404L135 405L134 404M231 404L232 405L231 404z" />
      <path fill="#434a2c" stroke="none" d="M232 404L233 405L232 404M136 405L137 406L136 405z" />
      <path fill="#507641" stroke="none" d="M137 405L138 406L137 405M227 405L228 406L227 405z" />
      <path fill="#434a2c" stroke="none" d="M228 405L229 406L228 405M139 406L140 407L139 406z" />
      <path fill="#507641" stroke="none" d="M140 406L141 407L140 406M223 406L224 407L223 406z" />
      <path fill="#434a2c" stroke="none" d="M224 406L225 407L224 406z" />
      <path fill="#507641" stroke="none" d="M142 407L143 408L142 407M219 407L220 408L219 407z" />
      <path fill="#434a2c" stroke="none" d="M220 407L221 408L220 407M145 408L146 409L145 408z" />
      <path fill="#507641" stroke="none" d="M146 408L147 409L146 408M215 408L216 409L215 408z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M216 408L217 409L216 408M149.667 409.333L150.333 409.667L149.667 409.333z"
      />
      <path
        fill="#507641"
        stroke="none"
        d="M151 409L152 410L151 409M209.667 409.333L210.333 409.667L209.667 409.333z"
      />
      <path
        fill="#434a2c"
        stroke="none"
        d="M211 409L212 410L211 409M308 409L307 411L308 409M154.667 410.333L155.333 410.667L154.667 410.333z"
      />
      <path
        fill="#507641"
        stroke="none"
        d="M156 410L157 411L156 410M203.667 410.333L204.333 410.667L203.667 410.333z"
      />
      <path
        fill="#434a2c"
        stroke="none"
        d="M205.667 410.333L206.333 410.667L205.667 410.333M160.667 411.333L161.333 411.667L160.667 411.333z"
      />
      <path fill="#507641" stroke="none" d="M162 411L162 412L165 412L162 411M194 411L194 412L197 412L194 411z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M197 411L197 412L200 412L197 411M305 411L306 412L305 411M170 412L170 413L190 413L170 412M303 412L302 414L303 412M300 414L301 415L300 414M298 415L293 418L293 419L299 416L298 415M291 419L292 420L291 419M289 420L290 421L289 420M287 421L288 422L287 421M285 422L286 423L285 422M283 423L284 424L283 423M274 427L275 428L274 427M101 428L103 430L101 428M104 430L105 431L104 430M106 431L106 432L110 434L110 433L106 431M262 432L263 433L262 432M259.667 433.333L260.333 433.667L259.667 433.333M111 434L112 435L111 434M113 435L114 436L113 435M250 436L250 437L255 436L250 436M115.667 436.333L116.333 436.667L115.667 436.333M118 437L119 438L118 437M247.667 437.333L248.333 437.667L247.667 437.333z"
      />
      <path fill="#507641" stroke="none" d="M249 437L250 438L249 437z" />
      <path
        fill="#434a2c"
        stroke="none"
        d="M120 438L121 439L120 438M244.667 438.333L245.333 438.667L244.667 438.333M122.667 439.333L123.333 439.667L122.667 439.333M241.667 439.333L242.333 439.667L241.667 439.333M125 440L126 441L125 440M128 441L129 442L128 441M131.667 442.333L132.333 442.667L131.667 442.333M134 443L134 444L137 444L134 443M138.667 444.333L139.333 444.667L138.667 444.333M142 445L142 446L145 446L142 445z"
      />
    </Svg>
  );
};

export default Icon;
